export const revalidateOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: true,
};

export * from './lib/types';
export * from './lib/notifier';
export * from './lib/fetch-api-hub';
export * from './lib/image';
