import tw from 'tailwind-styled-components';

export const ProductCardsContainer = tw.div`
    py-10 
    xl:py-[120px] 
    px-5 
    xl:px-[60px] 
`;

export const ProductCardsTitleContainer = tw.div`
    flex 
    flex-row 
    gap-2 
    pb-8
    md:pb-10
    xl:pb-16
`;

export const ProductCardsFooterContainer = tw.div`
    flex 
    justify-center 
    flex-col 
    md:flex-row 
    items-center 
    gap-4 
    md:gap-6 
    pt-8 
    xl:pt-16
`;
