import { fetchApiHub } from 'frontastic';

export const getProductPaginationApi = async (query?: string) => {
  try {
    const res = await fetchApiHub(`/action/product/pagination${query}`, { method: 'POST' });
    return res?.dataSourcePayload;
  } catch (err) {
    console.log('error', err);
    return { error: 400, data: {} };
  }
};
