/**
 * Try some synchronous execution until it is successful time by time.
 * e.g. Calling React ref instance method.
 *
 * @param eachTry Each try callback. Return a boolean to indicate whether it is successful or not.
 * @param tryTime The number of time to try
 * @param interval The interval between each try
 * @category utils
 * @module tryUntil
 */
const tryUntil = (handler, tryTime, interval = 100, callback?) => {
  const tryEach = (index, tryTime) => {
    const isDone = handler(index, tryTime);
    if (isDone) {
      callback?.(true);
    } else if (index + 1 === tryTime) {
      callback?.(false);
    } else {
      // Invoke next try
      setTimeout(() => {
        return tryEach(index + 1, tryTime);
      }, interval);
    }
  };
  // Invoke recursive function
  tryEach(0, tryTime);
};
export default tryUntil;
