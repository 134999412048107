import tw from 'tailwind-styled-components';

export const CarouselProductCardsBox = tw.div`
    flex 
    flex-col 
    overflow-hidden 
    bg-white 
    py-10 
    pl-5 
    lg:py-[120px] 
    lg:pl-[60px]
`;

export const CarouselProductCardsTitleBox = tw.div`
    flex 
    flex-col
    items-start
    lg:flex-row
    lg:items-center 
    justify-between 
    pb-[40px] 
    pr-5 
    lg:pr-[60px]
    gap-4
`;

export const CarouselProductCardsPaginationBox = tw.div`
    flex 
    flex-row 
    gap-6
`;
export const CarouselProductCardsPaginationMobileBox = tw.div`
    flex 
    flex-col
    gap-6 
    pt-6
`;

export const CarouselProductCardsPaginationSlider = tw.div`
    w-full 
    [&_div.slick-track]:flex
    [&_div.slick-track]:items-start
    [&_div.slick-track]:justify-start
    [&_div.slick-track]:gap-2.5
    [&_div.slick-track]:min-w-full
    md:[&_div.slick-track]:gap-5
    2xl:[&_div.slick-track]:gap-6
`;
