import { getMenuFiltersLink } from 'helpers/utils/common';
import {
  capitalizeLabel,
  extractPrices,
  getDefaultProductImageUrl,
  getProductUrl,
  variantFormate,
} from 'helpers/utils/product-details';
import { store } from 'redux/store';

export const productCardRestructureData = (algolia?: any, weightData?: any, setWeightData?: any) => {
  const host = store.getState().hostReducer.host;
  const isOH = host?.includes('oh');
  const menuLink = getMenuFiltersLink();
  const variant = variantFormate(algolia?.variants);
  const productId = weightData?.value || variant?.[0]?.value;
  const productData = (productId && algolia?.variants_details[productId]) || {};
  const defaultWeightSelect = productData?.price_eighth_ounce
    ? 'eighth_ounce'
    : variant?.find((item) => productData?.available_weights?.includes(item?.name?.replace('_', ' ')))?.name ||
      variant?.[0]?.name;
  const inventory_potencies =
    productData?.inventory_potencies?.find((item) => item?.price_id === (weightData?.name || defaultWeightSelect)) ||
    productData?.inventory_potencies?.[0];

  // FIXME: this function should be near to cart or product card not in the formatting method - GV
  const handleSelectedWeight = (item) => {
    setWeightData(item);
  };
  const { price_id, thc_potency, cbd_potency, tac_potency } = inventory_potencies || {};
  const { price, discounted_price, max_cart_quantity } = extractPrices(price_id, productData) || {};
  const gram = variant?.find((item) => item.name === (weightData?.name || defaultWeightSelect))?.label || '';
  const formatTags = (type: string) => {
    switch (type) {
      case 'vape':
      case 'flower':
      case 'pre-roll':
      case 'extract':
        return `${
          tac_potency || productData?.percent_thca ? `THCa ${tac_potency || productData?.percent_thca}%` : ''
        } ${thc_potency || productData?.percent_thc ? `THC ${thc_potency || productData?.percent_thc}%` : ''} ${
          cbd_potency || productData?.percent_cbd ? `CBD ${cbd_potency || productData?.percent_cbd}%` : ''
        }`;
      case 'edible':
      case 'tincture':
      case 'topical':
        return `${productData?.dosage ?? ''} ${productData?.amount ?? ''}`;
      default:
        return '';
    }
  };
  return {
    image:
      productData?.image_urls?.[0] ||
      getDefaultProductImageUrl(
        productData?.kind === 'flower' ? productData.kind : productData?.type,
        productData?.category,
        productData?.root_subtype,
      ),
    brandName: productData?.brand,
    type: capitalizeLabel(productData?.brand_subtype),
    name: productData?.name,
    gram: productData?.available_weights?.length > 0 ? gram : '',
    price: discounted_price ? String(parseFloat(discounted_price)) : String(parseFloat(price)) || null,
    discountPrice:
      discounted_price && String(parseFloat(discounted_price)) !== String(parseFloat(price))
        ? String(parseFloat(price))
        : undefined,
    reviews: (!isOH && productData?.review_count) || undefined,
    averageRating: (!isOH && productData?.aggregate_rating) || undefined,
    weights: variant || [],
    isShowWeightSelector: productData?.available_weights?.length > 0 || false,
    defaultWeightSelect: String(weightData?.name || defaultWeightSelect) || '',
    handleSelectedWeight: handleSelectedWeight,
    buttonText: variant?.length > 1 ? 'Select weight' : 'Add to cart',
    strainsLabel: capitalizeLabel(productData?.category) || '',
    saleLabel: productData?.special_amount || '',
    thc: formatTags(productData?.kind),
    url: getProductUrl(menuLink, productId, productData?.url_slug),
    kind: productData.kind,
    productId: productId,
    maxCartLimit: max_cart_quantity || productData?.max_cart_quantity,
  };
};
