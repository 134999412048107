import tw from 'tailwind-styled-components';

export const CarouselProductCardsCtaWapper = tw.div`
    flex 
    flex-col 
    overflow-hidden 
    bg-white 
    py-10 
    pl-5 
    lg:flex-row 
    lg:py-[120px] 
    lg:pl-[60px]
`;

export const CarouselProductCardsCtaBox = tw.div`
    2xl:mr-48
    xl:mr-32
    pr-6
    flex 
    w-full 
    flex-col 
    justify-between 
    lg:w-[432px]
    pb-[40px]
`;

export const CarouselProductCardsCtaMobileBox = tw.div`
    pt-6
`;

export const CarouselProductCardsSlider = tw.div`
    w-full 
    [&_div.slick-track]:flex
    [&_div.slick-track]:items-start
    [&_div.slick-track]:justify-start
    [&_div.slick-track]:gap-2.5
    [&_div.slick-track]:min-w-full
    md:[&_div.slick-track]:gap-5
    2xl:[&_div.slick-track]:gap-6
`;

export const CarouselProductCardsSliderButton = tw.a`
    rounded-md 
    border 
    border-solid 
    border-grey-300 
    !flex 
    items-center 
    justify-center 
    cursor-pointer 
    bg-leaf
    w-[260px]
    h-[440px] 
    md:w-full
    md:min-w-[288px]
    md:max-w-[400px]
    md:h-[594px]
    xl:min-w-[230px]
    xl:max-w-[432px]
    xl:h-[666px]
`;
