import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Grid } from 'react-virtualized';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import Pagination from 'components/atoms/pagination';
import { Typography } from 'components/atoms/typography';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { getLinkTarget } from 'helpers/utils/common';
import { desktop, smallMobile, tablet } from 'helpers/utils/screensizes';
import { RootState } from 'redux/store';
import { renderProductCard } from '../../carousel-product-cards-cta';
import {
  CarouselProductCardsBox,
  CarouselProductCardsPaginationBox,
  CarouselProductCardsPaginationMobileBox,
  CarouselProductCardsPaginationSlider,
  CarouselProductCardsTitleBox,
} from './styles';

type TProps = {
  title: string;
  onClick?: () => void;
  buttonTitle?: string;
  buttonLink?: string;
  paginationCount?: number;
  data: any;
  order: number;
};

const cardInfo: any = {
  mobile: { breakpoint: smallMobile, slide: 1 },
  tablet: { breakpoint: tablet, slide: 3 },
  desktop: { breakpoint: 1918, slide: 4 },
};

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 100,
});

const CarouselProductCardsWrapper = ({
  title,
  onClick,
  buttonTitle,
  paginationCount,
  buttonLink,
  data,
  order,
}: TProps) => {
  const host = useSelector((state: RootState) => state.hostReducer.host);
  const [isDesktop] = useMediaQuery(desktop);
  const [, width] = useMediaQuery(smallMobile);
  const [slickCurrentIndex, setSlickCurrentIndex] = useState(0);
  const columnWidth = width < tablet ? 280 : 450;
  const gridRef = useRef(null);

  const onChange = (p: any) => {
    //@ts-ignore
    gridRef.current.scrollToPosition({ scrollLeft: p?.startIndex * columnWidth, scrollTop: 0 });
  };

  const itemsPerPage =
    width < cardInfo?.tablet?.breakpoint
      ? cardInfo?.mobile?.slide
      : width < cardInfo?.desktop?.breakpoint
      ? cardInfo?.tablet?.slide
      : cardInfo?.desktop?.slide;

  useEffect(() => {
    const rewardSection = document.getElementById('rewards-section');
    if (rewardSection) {
      const el = document.getElementById('carousel-product-cards-box') as HTMLElement;
      rewardSection.appendChild(el);
    }
  }, []);

  const renderItem = (prop) => {
    const { key, rowIndex, columnIndex, style, parent } = prop;
    const id = columnIndex;
    if (!data || data?.length === 0 || id >= data?.length) return null;
    const item = data[id];

    return (
      <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={columnIndex} rowIndex={rowIndex}>
        {({ registerChild, measure }) => (
          <div style={style} className="row" role="row" ref={registerChild} onLoad={measure}>
            {renderProductCard(item, id, order)}
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <CarouselProductCardsBox id="carousel-product-cards-box">
      <CarouselProductCardsTitleBox>
        <Typography variant="h3">{title}</Typography>
        <CarouselProductCardsPaginationBox>
          {isDesktop && (
            <Pagination
              count={paginationCount as number}
              itemsPerPage={itemsPerPage}
              color="resin"
              variant="large"
              onPageChange={onChange}
              slickCurrentIndex={slickCurrentIndex}
            />
          )}
          {buttonTitle && (
            <CommonButton
              host={host}
              href={buttonLink || ''}
              target={getLinkTarget(buttonLink as string)}
              as="a"
              onClick={onClick}
              button-type="secondary"
              size="sm"
              color="green"
            >
              {buttonTitle}
            </CommonButton>
          )}
        </CarouselProductCardsPaginationBox>
      </CarouselProductCardsTitleBox>
      <CarouselProductCardsPaginationSlider>
        <AutoSizer disableHeight>
          {({ width }) => (
            <div>
              <Grid
                ref={(ref) => {
                  gridRef.current = ref;
                }}
                autoHeight
                width={width}
                height={500}
                columnWidth={columnWidth}
                columnCount={data.length}
                rowCount={1}
                rowHeight={cache.rowHeight}
                cellRenderer={renderItem}
                onSectionRendered={(p) => {
                  setSlickCurrentIndex(Math.ceil(p?.columnStartIndex / itemsPerPage));
                }}
              />
            </div>
          )}
        </AutoSizer>
      </CarouselProductCardsPaginationSlider>
      {!isDesktop && (
        <CarouselProductCardsPaginationMobileBox>
          <Pagination
            count={paginationCount as number}
            itemsPerPage={itemsPerPage}
            color="resin"
            variant="large"
            onPageChange={onChange}
            slickCurrentIndex={slickCurrentIndex}
          />
        </CarouselProductCardsPaginationMobileBox>
      )}
    </CarouselProductCardsBox>
  );
};

export default CarouselProductCardsWrapper;
