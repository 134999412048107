import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Grid } from 'react-virtualized';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import Pagination from 'components/atoms/pagination';
import { Typography } from 'components/atoms/typography';
import { RenderProductCard } from 'components/organisms/listings/product-cards';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { getLinkTarget } from 'helpers/utils/common';
import { desktop, smallMobile, tablet } from 'helpers/utils/screensizes';
import { RootState } from 'redux/store';
import {
  CarouselProductCardsCtaBox,
  CarouselProductCardsCtaMobileBox,
  CarouselProductCardsCtaWapper,
  CarouselProductCardsSlider,
  CarouselProductCardsSliderButton,
} from './styles';

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 100,
});

type TProps = {
  title: string;
  order?: number;
  buttonTitle?: string;
  buttonLink?: string;
  onClick?: () => void;
  description?: string;
  data?: any;
};

export const renderProductCard = (item, index, order) => {
  return item?.type === 'button' ? (
    <CarouselProductCardsSliderButton key={index} href={item?.url} target={getLinkTarget(item?.url)}>
      <Typography variant="body-small-bold" className="!text-white">
        {item?.buttonText}
      </Typography>
    </CarouselProductCardsSliderButton>
  ) : (
    <RenderProductCard
      data={item}
      id={index}
      order={order}
      variant="vertical"
      horizontalImagePosition="left"
      key={index}
    />
  );
};

const cardInfo: any = {
  mobile: { breakpoint: smallMobile, slide: 1 },
  tablet: { breakpoint: tablet, slide: 2 },
  desktop: { breakpoint: 1918, slide: 3 },
};

const CarouselProductCardsCta = ({ title, buttonTitle, onClick, description, data, buttonLink, order }: TProps) => {
  const [, width] = useMediaQuery(smallMobile);
  const columnWidth = width < tablet ? 280 : 450;
  const gridRef = useRef(null);
  const host = useSelector((state: RootState) => state.hostReducer.host);
  const [slickCurrentIndex, setSlickCurrentIndex] = useState(0);
  const [isDesktop] = useMediaQuery(desktop);

  const onChange = (p: any) => {
    //@ts-ignore
    gridRef.current.scrollToPosition({ scrollLeft: p?.startIndex * columnWidth, scrollTop: 0 });
  };

  const itemsPerPage =
    width < cardInfo?.tablet?.breakpoint
      ? cardInfo?.mobile?.slide
      : width < cardInfo?.desktop?.breakpoint
      ? cardInfo?.tablet?.slide
      : cardInfo?.desktop?.slide;
  const renderItem = (prop) => {
    const { key, rowIndex, columnIndex, style, parent } = prop;
    const id = columnIndex;
    if (!data || data?.length === 0 || id >= data?.length) return null;
    const item = data[id];

    return (
      <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={columnIndex} rowIndex={rowIndex}>
        {({ registerChild, measure }) => (
          <div style={style} className="row" role="row" ref={registerChild} onLoad={measure}>
            {renderProductCard(item, id, order)}
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <CarouselProductCardsCtaWapper>
      <CarouselProductCardsCtaBox>
        <div className="flex flex-col">
          {title && (
            <Typography variant="h3" className="pb-4 lg:pb-6">
              {title}
            </Typography>
          )}
          {description && (
            <Typography variant="body-large" className="pb-[16px] !text-grey-400 lg:pb-[44px]">
              {description}
            </Typography>
          )}
          {buttonTitle && (
            <div className="flex">
              <CommonButton
                host={host}
                href={buttonLink as string}
                target={getLinkTarget(buttonLink as string)}
                as="a"
                onClick={onClick}
                button-type="primary"
                size="sm"
                color="green"
              >
                {buttonTitle}
              </CommonButton>
            </div>
          )}
        </div>
        {isDesktop && (
          <Pagination
            isVisible={true}
            count={data?.length}
            itemsPerPage={itemsPerPage}
            color="resin"
            variant="large"
            onPageChange={onChange}
            slickCurrentIndex={slickCurrentIndex}
          />
        )}
      </CarouselProductCardsCtaBox>
      <CarouselProductCardsSlider>
        <AutoSizer disableHeight>
          {({ width }) => (
            <div>
              <Grid
                ref={(ref) => {
                  gridRef.current = ref;
                }}
                autoHeight
                width={width}
                height={500}
                columnWidth={columnWidth}
                columnCount={data.length}
                rowCount={1}
                rowHeight={cache.rowHeight}
                cellRenderer={renderItem}
                onSectionRendered={(p) => {
                  setSlickCurrentIndex(Math.ceil(p?.columnStartIndex / itemsPerPage));
                }}
              />
            </div>
          )}
        </AutoSizer>
      </CarouselProductCardsSlider>
      {!isDesktop && (
        <CarouselProductCardsCtaMobileBox>
          <Pagination
            isVisible={true}
            count={data?.length}
            itemsPerPage={itemsPerPage}
            color="resin"
            variant="large"
            onPageChange={onChange}
            slickCurrentIndex={slickCurrentIndex}
          />
        </CarouselProductCardsCtaMobileBox>
      )}
    </CarouselProductCardsCtaWapper>
  );
};

export default CarouselProductCardsCta;
