'use client';

import { useCallback, useEffect, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Grid, WindowScroller } from 'react-virtualized';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { TTypographyVariant, Typography } from 'components/atoms/typography';
import ProductCard from 'components/molecules/cards/product-card';
import useDispensary from 'helpers/hooks/use-dispensary';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { getProductPaginationApi } from 'helpers/product-pagination-api';
import { productCardRestructureData } from 'helpers/utils/product-card';
import { hugeDesktop, tablet } from 'helpers/utils/screensizes';
import tryUntil from 'helpers/utils/tryUntil';
import { ProductCardsContainer, ProductCardsFooterContainer, ProductCardsTitleContainer } from './styles';

type TRenderProductCardProps = {
  data: any;
  id: number;
  variant?: 'vertical' | 'vertical-horizontal' | 'horizontal';
  order?: number;
  containersStyle?: string;
  horizontalImagePosition?: 'left' | 'right';
};

export const RenderProductCard = ({
  data,
  id,
  variant,
  order,
  containersStyle,
  horizontalImagePosition,
}: TRenderProductCardProps) => {
  const [weightData, setWeightData] = useState([]);
  const item = productCardRestructureData(data, weightData, setWeightData);

  return (
    // @ts-ignore
    <ProductCard
      variant={variant || 'vertical-horizontal'}
      containersStyle={containersStyle}
      position={id}
      order={order}
      horizontalImagePosition={horizontalImagePosition}
      {...item}
    />
  );
};

type TProps = {
  cardsCountPerPage?: number;
  overScanCount?: number;
  title?: string;
  titleVariant?: TTypographyVariant;
  titleStyle?: string;
  showTitlePagination?: boolean;
  showFooterPagination?: boolean;
  showFooterMoreButton?: boolean;
  buttonText?: string;
  showFavorite?: boolean;
  initialData: any;
  totalProductCard?: number;
  totalPages?: number;
  handleButtonClick?: () => void;
  noResultComponent?: JSX.Element;
};

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 100,
});

const ProductCards = ({
  title,
  titleStyle,
  buttonText = 'See all product list',
  initialData,
  cardsCountPerPage = 12,
  showTitlePagination = true,
  showFooterPagination = true,
  showFooterMoreButton = true,
  totalProductCard = 0,
  titleVariant = 'h3',
  handleButtonClick,
  noResultComponent,
}: TProps) => {
  const [, width] = useMediaQuery(tablet);
  const { currentDispensary } = useDispensary();

  const columnCount = width < tablet ? 1 : width < hugeDesktop ? 3 : 4;
  const [productsFromServer, setProductsFromServer] = useState(initialData);
  const gridRef = useRef(null);
  const [counters, setCounters] = useState({
    currentPage: 0,
    displayCounter: initialData?.length > cardsCountPerPage ? cardsCountPerPage : initialData?.length,
    lastScrollPosition: 0,
  });
  const { currentPage, displayCounter, lastScrollPosition } = counters;
  const path = usePathname();
  // TODO: can we use this instead if router? - GV const searchParams = useSearchParams();
  const remainingCards = totalProductCard - productsFromServer?.length;

  useEffect(() => {
    //@ts-ignore
    gridRef?.current?.scrollToLastRow(lastScrollPosition);
  }, [lastScrollPosition]);

  const showMore = async () => {
    // TODO: this can be optimized by checking if we have enough in local object- GV
    const newDisplayCounter =
      totalProductCard - displayCounter > cardsCountPerPage ? displayCounter + cardsCountPerPage : totalProductCard;

    let data;
    if (remainingCards > 0 && newDisplayCounter > productsFromServer?.length) {
      const params = new URLSearchParams(path);
      const query = decodeURI(params?.toString()?.replace('%3F', '?'))?.split('?')[1];
      data = await getProductPaginationApi(
        `?${query}&page=${currentPage + 1}&store_id=${currentDispensary?.dispensaries?.storeId}`,
      );
    }
    let newCurrentPage = currentPage;
    const lastScrollPosition = window?.scrollY;
    if (data?.algolia) {
      setProductsFromServer([...productsFromServer, ...data.algolia]);
      newCurrentPage = data.algolia_page;
    }
    setCounters({
      ...counters,
      currentPage: newCurrentPage,
      displayCounter: newDisplayCounter,
      lastScrollPosition,
    });
  };

  const showMoreButtonText = handleButtonClick
    ? buttonText
    : `Show More ${
        totalProductCard - displayCounter > cardsCountPerPage ? cardsCountPerPage : totalProductCard - displayCounter
      }`;

  const productInfoData = productsFromServer?.length > 0 && productsFromServer?.slice(0, displayCounter);
  const rowCount = Math.ceil(productInfoData?.length / columnCount);
  const renderItem = ({ key, rowIndex, columnIndex, style, parent }) => {
    const id = rowIndex * columnCount + columnIndex;
    const width = style.width - 24;
    if (!productInfoData || productInfoData?.length === 0 || id >= productInfoData?.length) return null;
    return (
      <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={columnIndex} rowIndex={rowIndex}>
        {({ registerChild, measure }) => (
          <div style={{ ...style, width }} className="row" role="row" ref={registerChild} onLoad={measure}>
            <RenderProductCard
              data={productInfoData[id]}
              id={id}
              key={id}
              containersStyle="mb-4 2xl:mb-16 min-h-[222px]"
            />
          </div>
        )}
      </CellMeasurer>
    );
  };

  const handleRef = useCallback((ref) => {
    if (ref !== null) {
      // Override and fix scrollToPosition
      ref.scrollToPosition = ({ scrollTop }) =>
        tryUntil(() => {
          const scrollEl: any = window;
          if (scrollEl) {
            scrollEl.scrollTo({ top: scrollTop });
            const updatedScrollTop = scrollEl?.scrollTop || scrollEl?.Y || 0;
            return updatedScrollTop === scrollTop;
          }
          // Try next
          return false;
        }, 3);
      ref.scrollToLastRow = (scrollY: number) => {
        ref.scrollToPosition({ scrollTop: scrollY });
      };
    }
  }, []);

  const handleClick = () => {
    if (handleButtonClick) {
      handleButtonClick();
    } else {
      showMore();
    }
  };

  const renderNoResult = () => {
    if (noResultComponent) return noResultComponent;
    return (
      <Typography className="w-full px-5 py-10 !text-grey-480  md:w-1/2 xl:px-[60px] xl:py-[120px]" variant="h2">
        {`No exact matches found. Try a similar search with new keywords and hopefully we'll find something for you.`}
      </Typography>
    );
  };

  return productInfoData?.length > 0 ? (
    <ProductCardsContainer>
      <ProductCardsTitleContainer>
        {title && (
          <Typography variant={titleVariant} className={`!text-grey-500 ${titleStyle}`}>
            {title}
          </Typography>
        )}
        {showTitlePagination && (
          <Typography variant="body-small" className="!text-grey-400">
            ({`${cardsCountPerPage < totalProductCard ? cardsCountPerPage : totalProductCard}`} of{' '}
            {`${totalProductCard}`})
          </Typography>
        )}
      </ProductCardsTitleContainer>
      <>
        <WindowScroller>
          {({ height, scrollTop, registerChild, onChildScroll, isScrolling }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <div ref={(el: any) => registerChild(el)}>
                  <Grid
                    ref={(ref) => {
                      handleRef(ref);
                      gridRef.current = ref;
                    }}
                    autoHeight
                    // autoContainerWidth
                    isScrolling={isScrolling}
                    width={width}
                    height={height}
                    scrollTop={scrollTop}
                    scrollToColumn={5}
                    columnWidth={width / columnCount}
                    columnCount={columnCount}
                    rowCount={rowCount}
                    rowHeight={cache.rowHeight}
                    cellRenderer={renderItem}
                    onScroll={onChildScroll}
                    // deferredMeasurementCache={cache}
                  />
                </div>
              )}
            </AutoSizer>
          )}
        </WindowScroller>

        {(showFooterMoreButton || showFooterPagination) && (
          <ProductCardsFooterContainer>
            {showFooterPagination && (
              <Typography variant="body-small">
                Showing {`${displayCounter}`} of {`${totalProductCard}`}
              </Typography>
            )}
            {showFooterMoreButton && totalProductCard - displayCounter > 0 && (
              <CommonButton onClick={handleClick} button-type="secondary" color="green" size="sm">
                {showMoreButtonText}
              </CommonButton>
            )}
          </ProductCardsFooterContainer>
        )}
      </>
    </ProductCardsContainer>
  ) : (
    renderNoResult()
  );
};

export default ProductCards;
