import CarouselProductCardsCta from './carousel-product-cards-cta';
import CarouselProductCardsWrapper from './carousel-product-cards/carousel-product-cards-wrapper';

type TProps = {
  type: 'Left CTA' | 'Top CTA' | 'NO CTA' | 'No CTA';
  title: string;
  order?: number;
  description?: string;
  buttonTitle?: string;
  kind?: string;
  buttonLink?: string;
  data?: Array<any>;
  onClick?: () => void;
  algoliaValue?: string;
};

const CarouselProduct = ({
  type = 'Left CTA',
  title,
  description,
  buttonTitle,
  buttonLink,
  data,
  order,
  onClick,
  algoliaValue,
}: TProps) => {
  data?.push({ buttonText: `See all ${title || algoliaValue || ''}`, url: buttonLink, type: 'button' });

  switch (type) {
    case 'Left CTA':
      return (
        <CarouselProductCardsCta
          order={order}
          data={data}
          title={title}
          description={description}
          buttonLink={buttonLink}
          buttonTitle={buttonTitle}
          onClick={onClick}
        />
      );
    case 'Top CTA':
      return (
        <CarouselProductCardsWrapper
          title={title}
          paginationCount={data?.length}
          buttonLink={buttonLink}
          buttonTitle={buttonTitle}
          onClick={onClick}
          data={data}
          order={order as number}
        />
      );
    case 'No CTA':
    case 'NO CTA':
      return (
        <CarouselProductCardsWrapper title={title} paginationCount={data?.length} data={data} order={order as number} />
      );
  }
};

export default CarouselProduct;
